$(document).ready(function() {
  initActionMultiple();
  initAutocomplete();
  initCheckAll();
  initMultiSelect();
  initDatePicker();
});

function initActionMultiple()
{
  $(".action-multiple").each(function () {
    $(this).on("click", function (event) {
      event.preventDefault();
      $(this).parents("form").attr("action", $(this).attr("href")).submit();
    });
  });
}

function initCheckAll()
{
  $('.check-all').change(function() {
    var isChecked = $(this).is(':checked');

    $('.' + $(this).data('target')).each(function() {
      $(this).prop('checked', isChecked);
    });
  });
}

function initAutocomplete(){
  $('.autocomplete').each(function(){
    var field = $(this);
    var _id = field.data('target');
    var list = $('#' + _id + '_list');
    var originValue = field.val();

    if (false == field.data('required')) {
      field.removeAttr('required');
    }

    list.on({
      'click.item-autocomplete': function (e) {
        $(this).closest('li').fadeOut(300, function(){
          $(this).remove();
        });
        e.preventDefault();
        e.stopPropagation();
      }
    }, 'a');

    field.on('change', function(e){
      if ('' == field.val()) {
        $('#' + _id).val('');
      }
    });

    field.autocomplete({
      deferRequestBy: field.data('delay'),
      lookupLimit: field.data('max-items'),
      minChars: field.data('min-chars'),
      serviceUrl: field.data('url'),
      onSelect: function (suggestion) {
        if (false == field.data('multiple')) {
          $('#'+field.data('target')).val(suggestion.data);
          originValue = suggestion.value;
        } else {
          var formName = field.closest('form').prop('name');

          if (list.length) {
            var li = list.find('#' + _id + '_' + suggestion.data);

            if (!li.length) {
              var name = (formName ? _id.replace(formName + '_', '') : _id).split('_');

              if (formName) {
                name.unshift(formName);
              }

              name = (name.length > 1 ? name.shift() + '[' + name.join('][') + ']' : name.join()) + '[]';

              li = $($('#' + _id).data('prototype'));
              li.data('value', suggestion.data)
                .find('input:hidden').val(suggestion.data).attr('id', _id + '_' + suggestion.data).attr('name', name).end()
                .find('.autocomplete-item').text(suggestion.value).end()
                .appendTo(list)
              ;
            }

            field.val('');
          }
        }
      }
    });
  });
}

function initMultiSelect(){
  $('.multiselect').each(function(){
    var
      enableFiltering = true,
      nonSelectedText = 'Sélectionner',
      selectAllText = 'Tout sélectionner',
      nSelectedText = ' sélectionnés',
      maxHeight = 400
    ;

    if ($(this).data('enable-filtering') || 'undefined' != typeof $(this).data('enable-filtering')) {
      enableFiltering = $(this).data('enable-filtering');
    }

    if ($(this).data('non-select-text') || 'undefined' != typeof $(this).data('non-select-text')) {
      nonSelectedText = $(this).data('non-select-text');
    }

    if ($(this).data('select-all-text') || 'undefined' != typeof $(this).data('select-all-text')) {
      selectAllText = $(this).data('select-all-text');
    }

    if ($(this).data('n-selectd-text') || 'undefined' != typeof $(this).data('n-selectd-text')) {
      selectAllText = $(this).data('n-selectd-text');
    }

    if ($(this).data('max-height') || 'undefined' != typeof $(this).data('max-height')) {
      maxHeight = $(this).data('max-height');
    }

    $(this).multiselect({
      enableCaseInsensitiveFiltering: enableFiltering,
      enableFiltering: enableFiltering,
      filterPlaceholder: 'Rechercher...',
      includeSelectAllOption: true,
      nonSelectedText: nonSelectedText,
      allSelectedText: 'Tout sélectionné',
      selectAllText: selectAllText,
      nSelectedText: nSelectedText,
      numberDisplayed: 1,
      maxHeight: maxHeight,
      buttonWidth: '100%',
      templates: {
        filter: '<li class="multiselect-item filter"><div class="input-group"><input class="form-control multiselect-search" type="text"></div></li>',
        filterClearBtn: '<span class="input-group-btn"><button class="btn btn-default multiselect-clear-filter" type="button"><i class="fa fa-times-circle"></i></button></span>'
      }
    });
  })
}

function initDatePicker() {
    $('.datepicker').datepicker({
        format: 'dd/mm/yyyy',
        language: 'fr',
        orientation: "bottom" // <-- orientation en bas
    });

    $('.datetimepicker').datetimepicker({
        locale: 'fr',
        icons: {
            time: 'fas fa-clock',
            date: 'fas fa-calendar',
            up: 'fas fa-chevron-up',
            down: 'fas fa-chevron-down',
            previous: 'fas fa-chevron-left',
            next: 'fas fa-chevron-right',
            today: 'fas fa-crosshairs',
            clear: 'fas fa-trash',
            close: 'fas fa-remove',
            orientation: "bottom" // <-- orientation en bas
        }
    });

    $(".datepickerOnlyYear").datepicker( {
        format: " yyyy", // Notice the Extra space at the beginning
        viewMode: "years",
        minViewMode: "years",
        orientation: "bottom" // <-- orientation en bas
    });

    $('.datepickerOnlyMonthAndDay').datepicker( {
        changeMonth: true,
        showButtonPanel: true,
        dateFormat: 'dd/MM',
        language: 'fr',
        orientation: "bottom" // <-- orientation en bas
    });

    $('.datepickerFullDate').datepicker( {
        changeMonth: true,
        showButtonPanel: true,
        dateFormat: 'dd/MM/yyyy',
        language: 'fr',
        orientation: "bottom" // <-- orientation en bas
    });
}
