$('.form-table-submit').on('click',function(e){
  e.preventDefault();
  $button = $(this);

  if($button.hasClass('form-table-group-toggle')) {
      $('.group-addCustomer-layout').toggle();
      return;
  }

  if ($('.'+$button.attr('data-check')+':checked').length == 0) {
    modalAlert('Veuillez effectuer une sélection');
  } else {
      $formTable = $("#" + $button.attr('data-form'));
      $formTable.attr('action', $button.attr('data-action'));

      if ($button.hasClass('form-table-delete')) {
          modalConfirmDelete(function () {
              $formTable.submit();
          });
      } else {
      $formTable.submit();
    }
  }
});
