$(document).ready(function(){

    $('[data-confirm="confirm-something"]').each(function(){
        $element = $(this);

        $element.on('click', function(){
            var href = $(this).data('href');
            modalConfirmSomething(function(){
                redirect(href);
            });

        });
    });

  $('[data-confirm="confirm-delete"]').each(function(){
    $element = $(this);

    $element.on('click', function(){
        var href = $(this).data('href');
      modalConfirmDelete(function(){
        redirect(href);
      });

    });
  });

    $('[data-confirm="confirm-marketing-campaign-plannified"]').each(function(){
        $element = $(this);

        $element.on('click', function(){
            var href = $(this).data('href');
            modalConfirmMarketingCampaignPlannified(function(){
                redirect(href);
            });

        });
    });
});

function redirect(url)
{
  window.location.href = url;
}

function modalConfirm(params)
{
  var defaultParams = {
    title: 'Confirmation',
    message: 'Êtes-vous sûr ?',
    buttons: {
      cancel: {
        label: 'Non'
      },
      confirm: {
        label: 'Oui'
      }
    },
    callback: function(result) {
    }
  };

  if (undefined == params) {
    params = defaultParams;
  }
  bootbox.confirm(params);
}

function modalAlert(message, title)
{
  if (undefined == title) {
    title = 'Attention';
  }

  bootbox.alert({
    message: message,
    title: title
  });
}

function modalConfirmSomething(callback)
{
    var params = {
        title: "Confirmation",
        message: "Êtes-vous sur ?",
        buttons: {
            cancel: {
                label: '<i class="fa fa-times"></i> Annuler'
            },
            confirm: {
                label: '<i class="fa fa-check"></i> Oui'
            }
        },
        callback: function (result) {
            if(result == true) {
                callback();
            }
        }
    };
    modalConfirm(params)
}

function modalConfirmMarketingCampaignPlannified(callback)
{
    var params = {
        title: "Confirmation de plannification",
        message: "Vous êtes sur le point de confirmer l'envoi de votre campagne à la date programmée. Etes-vous sûr ?",
        buttons: {
            cancel: {
                label: '<i class="fa fa-times"></i> Annuler'
            },
            confirm: {
                label: '<i class="fa fa-check"></i> Oui'
            }
        },
        callback: function (result) {
            if(result == true) {
                callback();
            }
        }
    };
    modalConfirm(params)
}


function modalConfirmDelete(callback)
{
  var params = {
      title: "Supprimer",
      message: "Êtes-vous sûr de vouloir supprimer ?",
      buttons: {
          cancel: {
              label: '<i class="fa fa-times"></i> Annuler'
          },
          confirm: {
              label: '<i class="fa fa-check"></i> Oui, supprimer'
          }
      },
      callback: function (result) {
        if(result == true) {
          callback();
        }
      }
  };
  modalConfirm(params)
}
